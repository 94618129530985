@import 'assets/scss/variables.module';

.form {
  & > div {
    display: flex;
    flex-direction: column;
  }

  label {
    font-weight: bold;
  }

  button {
    margin-top: 1.5rem;
    width: 100%;
  }
}

.rememberMe {
  width: 100%;
  margin: 10px 0;

  label {
    color: $color !important;
    transform: filter 0.2s ease;

    &:hover,
    :focus {
      filter: brightness(1.1);
    }
  }
}

.loading {
  display: flex;
  gap: 5px;
  justify-content: center;
  width: 100%;
  height: 15px;
}

.branding {
  color: $color;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
  font-size: 16px;
  font-weight: bold;

  img {
    width: 100px;
  }

  @include response-small {
    flex-direction: row;
    justify-content: center;
    align-items: center;

    img {
      width: 25px;
      height: 25px;
    }
  }
}

body:global(.dark-mode) .branding {
  img {
    filter: invert(1);
  }
}

.extras {
  display: flex;
  flex-wrap: nowrap;
  color: $color;
  gap: 5px;
  font-weight: bold;

  margin: 1rem 0;

  a {
    color: $archidekt-orange;
    background: none;
    border: none;

    transition: filter 0.2s ease;

    &:hover:enabled {
      cursor: pointer;
      filter: brightness(1.2);
    }
  }
}

.wrapper {
  @include response-small {
    margin-top: 50px;
  }
}

.developmentWarning {
  margin: 1rem 0;

  width: 100%;

  background-color: $archidekt-orange;
  border-radius: 8px;
  border: 1px solid $light-color2;

  color: white;
  font-size: 14px;

  button {
    width: 100%;
    height: 100%;
    padding: 1rem;

    font-weight: bold;
    color: white;
    background: none;
    border: none;

    transition: filter 0.2s ease;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.25rem;

    &:hover:enabled {
      cursor: pointer;
      filter: brightness(1.2);
    }
  }
}
