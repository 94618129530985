.flex {
  display: flex;
  align-items: center;

  margin: 0.5rem 0;

  gap: 0.5rem;
}

@mixin flex-start {
  @extend .flex;

  justify-content: flex-start;
}

@mixin flex-end {
  @extend .flex;

  justify-content: flex-end;
}

@mixin flex-center {
  @extend .flex;

  justify-content: center;
}

@mixin flex-around {
  @extend .flex;

  justify-content: space-between;
}

@mixin flex-even {
  @extend .flex;

  justify-content: space-evenly;
}
