$extreme-large: 2150px;
$xxl-large: 1900px;
$xl-large: 1700px;
$x-large: 1500px;
$large: 1200px;
$medium: 1000px;
$medium-small: 800px;
$small: 600px;
$x-small: 400px;
$extreme-small: 320px;

// Below N media queries
@mixin response-extreme-large {
  @media (max-width: $extreme-large) {
    @content;
  }
}

@mixin response-xxl-large {
  @media (max-width: $xxl-large) {
    @content;
  }
}

@mixin response-xl-large {
  @media (max-width: $xl-large) {
    @content;
  }
}

@mixin response-x-large {
  @media (max-width: $x-large) {
    @content;
  }
}

@mixin response-large {
  @media (max-width: $large) {
    @content;
  }
}

@mixin response-medium {
  @media (max-width: $medium) {
    @content;
  }
}

@mixin response-medium-small {
  @media (max-width: $medium-small) {
    @content;
  }
}

@mixin response-small {
  @media (max-width: $small) {
    @content;
  }
}

@mixin response-x-small {
  @media (max-width: $x-small) {
    @content;
  }
}

@mixin response-extreme-small {
  @media (max-width: $extreme-small) {
    @content;
  }
}

@mixin response-min-medium-small {
  @media (min-width: $medium-small) {
    @content;
  }
}

// Above N media queries
@mixin response-min-small {
  @media (min-width: $small) {
    @content;
  }
}

@mixin response-min-large {
  @media (min-width: $large) {
    @content;
  }
}

@mixin response-min-x-large {
  @media (min-width: $x-large) {
    @content;
  }
}

// Between N and M media queries
@mixin response-xl-large-to-xxl-large {
  @media (min-width: $xl-large) and (max-width: $xxl-large) {
    @content;
  }
}

@mixin response-x-large-to-xl-large {
  @media (min-width: $large) and (max-width: $xl-large) {
    @content;
  }
}

@mixin response-large-to-x-large {
  @media (min-width: $large) and (max-width: $x-large) {
    @content;
  }
}

@mixin response-medium-to-large {
  @media (min-width: $medium) and (max-width: $large) {
    @content;
  }
}

@mixin response-medium-small-to-medium {
  @media (min-width: $medium-small) and (max-width: $medium) {
    @content;
  }
}

@mixin response-small-to-medium-small {
  @media (min-width: $small) and (max-width: $medium-small) {
    @content;
  }
}

@mixin response-small-to-medium {
  @media (min-width: $small) and (max-width: $medium) {
    @content;
  }
}

@mixin response-x-small-to-small {
  @media (min-width: $x-small) and (max-width: $small) {
    @content;
  }
}
