// Put theme and other resuable variables here

@import './mixins/flex.module.scss';
@import './mixins/misc.module.scss';
@import './mixins/mediaQueries.module.scss';
@import './mixins/animations.module.scss';
@import './mixins/grid.module.scss';
@import './mixins/deckPageTableView.module.scss';
@import './mixins/mobile.module.scss';

$button-grey: #e0e1e2;
$button-grey-text-color: #00000099;

$button-green: #1ebb6c;
$button-red: #ff555b;
$button-blue: #4286f4;
$button-purple: #6435c9;
$code-font-color: #d63384;

$archidekt-orange: #fa890d;
$archidket-orange-transparnet: var(
  --archidekt-orange-transparent
); // This var is in global so it can be accessed via css vars

$archidekt-purple: #a42efd;

$background-color: var(--background-color);
$rgb-background: var(--rgb-background);
$light-background: var(--light-background);
$light-background2: var(--light-background2);
$light-background3: var(--light-background3);
$alt-background: var(--alt-background);
$color: var(--color);
$secondary-text-color: var(--secondary-text-color);
$light-color: var(--light-color);
$light-color2: var(--light-color2);
$light-color3: var(--light-color3);
$rgb-color: var(--rgb-color);
$table-border-color: var(--table-border-color);
$table-alternate-color: var(--table-alternate-color);
$link-color: var(--link-color);
$framing-color: var(--framing-color);
$rgb-framing-color: var(--rgb-framing);
$card-border: var(--card-border);
$input-border-color: var(--input-border-color);

$secondary-color: var(--secondary-color);
$tertiary-color: var(--tertiary-color);

$table-selected: var(--table-selected);
$table-drop: var(--table-drop);

$overlay-background-color: var(--overlay-background);
$scrollbar-thumb: var(--scrollbar-thumb);
$scrollbar-thumb-hover: var(--scrollbar-thumb-hover);

$card-button-green: var(--card-button-green);
$card-button-red: var(--card-button-red);
$card-button-blue: var(--card-button-blue);

$quantity-green: #1ebb6c;
$quantity-blue: #698bc7;
$quantity-red: #f21b3f;

$generic-box-shadow: 2px 3px 7px -1px rgba(0, 0, 0, 0.5);

$toolbar-height: 50px;
$fix-mobile-ad-height: 50px;

$toolbar-offset: 0;
$toolbar-offset-small: 0;

$mtg-white: #fbf6d3;
$mtg-blue: #96c4d4;
$mtg-black: #b0a8a3;
$mtg-red: #d88f73;
$mtg-green: #93cba4;
$mtg-colorless: #a9a8a8;

@mixin sticky-offset {
  top: $toolbar-offset;

  @include response-small {
    top: $toolbar-offset-small;
  }
}

:export {
  archidektOrange: $archidekt-orange;
  quantityBlue: $quantity-blue;
  quantityGreen: $quantity-green;
}

@mixin small-table {
  border-collapse: collapse;
  width: 100%;

  tbody {
    border: solid 2px $table-border-color;
  }

  th {
    text-align: left;
  }

  tr {
    height: 35px;
  }

  tr:nth-child(odd) {
    background-color: $table-alternate-color !important;
  }

  tr:nth-child(even) {
    background-color: $light-background !important;
  }

  td:first-child,
  th:first-child {
    padding-left: 10px;
  }
}

@mixin stripes {
  & > *:nth-child(odd) {
    background-color: $alt-background;
  }

  & > *:nth-child(even) {
    background-color: transparent;
  }
}
